<template>
  <main class="color-primary h-100 page_bg_grey blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <p class="main-title weight-700">
      {{ $t("Prepaid profit percentage") }}
    </p>
    <section class="content-margin">
      <DRFilter @changeBusiness="changeBusiness"></DRFilter>
      <div class="px-5 mb-15">
        <div class="fee-block d-flex align-items-center flex-wrap">
          <v-checkbox :disabled="!canBeEdited" color="#0D3856" class="cus-checkbox" v-model="use_distribution_fee"></v-checkbox>
          <label class="mx-5">{{ $t('Using a distribution fee') }}</label>

          <button v-if="canBeEdited" class="primary-btn primary-btn_sm mx-15" @click="saveDistributionFee">
            {{ $t('Save') }}
          </button>
        </div>
      </div>

      <div class="d-flex flex-wrap">
        <div class="w-25 form-div px-5 mb-10">
          <label>{{ $t('Select a supplier') }}</label>
           <vue-select
            :options="suppliersList.options"
            class="input cus-select"
            v-model="suppliersList.selected"
            @input="changeSuppliersList"
          ></vue-select>
        </div>
        <div class="w-25 form-div px-5 mb-10">
          <label>{{ $t('Choose a product') }}</label>
          <vue-select
            :options="suppliersProduct.options"
            class="input cus-select"
            v-model="suppliersProduct.selected"
            @input="changeSuppliersProduct"
          ></vue-select>
        </div>
        <div class="w-25 form-div px-5 mb-10">
          <label>{{ $t('Type of product') }}</label>
           <vue-select
            :options="productTypes.options"
            class="input cus-select"
            v-model="productTypes.selected"
            @input="changeProductTypes"
          ></vue-select>
        </div>
      </div>

      <div class="w-75 px-5 mt-25">
        <vue-slider
          :disabled="!canBeEdited"
          v-model="sliderValue"
          :direction="sliderDirection"
          class="cus-vue-slider"
          tooltip="always"
          :min="0"
          :max="100"
        ></vue-slider>
      </div>

      <div v-if="canBeEdited" class="d-flex mt-25 flex-wrap">
        <div class="w-25 px-5">
          <label>{{ $t("% profit") }}</label>
          <input type="text" class="input" v-model="productProfit">
        </div>
        <div class="w-50 px-5">
          <label></label>
          <button class="primary-btn fs16" @click="applyProfit" v-if="productProfit.length">
            {{ $t('Apply % profit on all products') }}
          </button>
          <button class="primary-btn fs16" v-else disabled>
            {{ $t('Apply % profit on all products') }}
          </button>
        </div>
      </div>

      <div v-if="canBeEdited" class="d-flex mt-10 flex-wrap">
        <div class="w-25 px-5">
          <label>{{ $t('Points') }}</label>
          <input type="text" class="input" v-model="ProductPoint">
        </div>
        <div class="w-50 px-5">
          <label></label>
          <button class="primary-btn fs16" @click="applyPoint" v-if="ProductPoint.length">
            {{ $t('Apply gift points to all products') }}
          </button>
          <button class="primary-btn fs16" v-else disabled>
            {{ $t('Apply gift points to all products') }}
          </button>
        </div>
      </div>
    </section>

    <section class="table-content-part mt-20">
      <div class="d-flex justify-content-between">
        <div class="search-bar">
          <p class="search-label">{{ $t('Search') }}</p>
          <v-text-field
            v-model="search"
            single-line
            hide-details
            class="search-form"
          ></v-text-field>
        </div>
      </div>
      <v-data-table
        :headers="_headers"
        :search="search"
        :items="tableData"
        class="cus-table editable-table main-table striped-table"
        item-key="id"
        :key="isUseDistributionFee"
      >
        <template v-for="h in _headers" v-slot:[`header.${h.value}`]="{ }">
          <div :key="h.value" class="d-flex align-items-center flex-column">
            <span>{{h.text}}</span>
            <v-checkbox
              :disabled="!canBeEdited"
              color="#0D3856" 
              class="cus-checkbox" 
              v-if="h.value=='distribution_fee'"
              v-model="a_distributionAll"
            ></v-checkbox>
            <v-checkbox
              :disabled="!canBeEdited"
              color="#0D3856" 
              class="cus-checkbox" 
              v-else-if="h.value=='is_authorized'"
              v-model="enabledAll"
            ></v-checkbox>
          </div>
        </template>
        <template v-slot:[`item.distribution_fee`]="{ item }">
          <div style="display: flex; justify-content: center;">
            <v-checkbox :disabled="!canBeEdited" color="#0D3856" class="cus-checkbox" v-model="item.distribution_fee"></v-checkbox>
          </div>
        </template>
        <template v-slot:[`item.product_type`]="{ item }">
          {{ productTypeStringArr[item.product_type] }}
        </template>
        <template v-slot:[`item.final_commission`]="{ item }">
          <div style="display: flex; justify-content: center;">
            <input :disabled="!canBeEdited" type="text" class="input" v-model="item.final_commission">
          </div>
        </template>
        <template v-slot:[`item.percentage_profit`]="{ item }">
          <div style="display: flex; justify-content: center;">
            <input :disabled="!canBeEdited" type="text" class="input" v-model="item.percentage_profit">
          </div>
        </template>
        <template v-slot:[`item.points`]="{ item }">
          <div style="display: flex; justify-content: center;">
            <input :disabled="!canBeEdited" type="text" class="input" v-model="item.points">
          </div>
        </template>
        <template v-slot:[`item.is_authorized`]="{ item }">
          <div style="display: flex; justify-content: center;">
            <v-checkbox :disabled="!canBeEdited" color="#0D3856" class="cus-checkbox" v-model="item.is_authorized"></v-checkbox>
          </div>
        </template>
      </v-data-table>
    </section>

    <div class="content-margin mb-20">
      <button v-if="canBeEdited" class="primary-btn btn-limit-width mt-20" @click="updateTableData()">
        {{ $t("Save") }}
      </button>
    </div>
    <ConfirmModal @confirmData="confirmData">
      <template v-slot:modal-content>
        {{ $t('This action will lead to changes in all products, including those that not shown after filtration! Are you sure you want to continue?') }}
      </template>
    </ConfirmModal>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
  </main> 
</template>

<script>
import { mapState, mapActions } from 'vuex';
import DRFilter from "../../../components/management/DRFilter.vue"
import ConfirmModal from "../../../components/management/ConfirmModal.vue"

export default {
  name: 'DRPercentageProfit',
  components: {
    DRFilter,
    ConfirmModal
  },
  data() {
    return {
      search: '',
      productTypeStringArr: [
        "",
        this.$t('Virtual'),
        this.$t('Manual'),
        this.$t('Payment of bills'),
      ],
      sliderValue: [0, 50],
      suppliersList: {
        options: [],
        selected: ""
      },
      productTypes: {
        options:[
          {
            code: 0,
            label: this.$t('All')
          },
          {
            code: 1,
            label: this.$t('Virtual')
          },
          {
            code: 2,
            label: this.$t('Manual')
          },
          {
            code: 3,
            label: this.$t('Payment of bills')
          },
        ],
        selected: {
          code: 0,
          label: this.$t('All')
        }
      },
      suppliersProduct: {
        options: [],
        selected: ""
      },
      tableData: [],
      filteredTableData: [],
      businessID: "",
      distributeID: "",
      productProfit: "",
      ProductPoint: "",
      canBeEdited: false,
      isProfitConfirm: true,
      use_distribution_fee: false,
      isUseDistributionFee: false,
      selectedBusiness_data: {}
    }
  },
  computed: {
    ...mapState({
      businessProductData: (state) => state.seller_tourism_percentage.businessProductData,
      suppliersData: (state) => state.seller_tourism_percentage.suppliersData,
      businessData: (state) => state.seller_tourism_percentage.businessData,
      loading: (state) => state.seller_tourism_percentage.loading
    }),
    a_distributionAll: {
      get: function () {
        const checkedLength = this.tableData.filter(function(item) {
          return item.distribution_fee!=null && item.distribution_fee!=false;
        }).length;
        if (checkedLength == this.tableData.length) {
          return true
        } 
        return false;
      },
      set: function (value) {
        this.tableData.forEach(item => {
          item.distribution_fee = value;
        })
      }
    },
    enabledAll: {
      get: function () {
        const checkedLength = this.tableData.filter(function(item) {
          return item.is_authorized==false;
        }).length;
        if (checkedLength) {
          return false
        } 
        return true;
      },
      set: function (value) {
        this.tableData.forEach(item => {
          item.is_authorized = value;
        })
      }
    },
    _headers () {
      let headerArray = [];
      const headerFront = [
        {
          text: this.$t('Supplier ID'),
          align: 'center',
          sortable: false,
          value: 'supplier_id'
        },
        {
          text: this.$t('Product Name'),
          align: 'center',
          sortable: false,
          value: 'product_name'
        },
        {
          text: this.$t('Type of product'),
          align: 'center',
          sortable: false,
          value: 'product_type'
        },
        {
          text: this.$t('Price'),
          align: 'center',
          sortable: false,
          value: 'final_commission'
        },
        {
          text: this.$t('Percentage Profit'),
          align: 'center',
          sortable: false,
          value: 'percentage_profit'
        },
        // {
        //   text: this.$t('Article {num}', { num: '20' }),
        //   align: 'center',
        //   sortable: false,
        //   value: 'article',
        // },
        {
          text: this.$t('Points'),
          align: 'center',
          sortable: false,
          value: 'points'
        },
      ]

      const headerBack = [
        {
          text: this.$t('Enabled'),
          align: 'center',
          sortable: false,
          value: 'is_authorized'
        }
      ]
      const headerDistribution = [
        {
          text: this.$t('A. Distribution'),
          align: 'center',
          sortable: false,
          value: 'distribution_fee'
        },
      ]

      if (this.isUseDistributionFee) {
        headerArray = [...headerFront, ...headerDistribution, ...headerBack];
      } else {
        headerArray = [...headerFront, ...headerBack];
      }
      return headerArray;
    }
  },
  methods: {
    ...mapActions('seller_tourism_percentage', {
      getBusinessProduct: 'getBusinessProduct',
      getSuppliers: 'getSuppliers',
      getBusiness: 'getBusiness',
      updateProductProfit: 'updateProductProfit',
      updateProductPoint: 'updateProductPoint',
      updateDistributionFee: 'updateDistributionFee',
      updateBusinessProduct: 'updateBusinessProduct'
    }),
    async changeBusiness(data) {
      this.businessID = data.business_id;
      this.distributeID = data.distribute_id;
      await this.getBusinessProduct({id: data.business_id});
      this.tableData = this.businessProductData;
      await this.getSuppliers({id: data.business_id});
      let suppliers_list = [];
      this.suppliersData.forEach(item => {
        suppliers_list.push({
          code: item.supplier_id,
          label: item.supplier_name
        })
      });
      this.suppliersList.options = suppliers_list;
      this.suppliersList.selected = "";
      this.productTypes.selected = {
        code: 0,
        label: this.$t('All')
      }
      this.selectedBusiness_data = this.businessData.find((el) => {
        return el.id == data.business_id;
      });
      if (this.selectedBusiness_data.use_distribution_fee) {
        this.use_distribution_fee = true;
        this.isUseDistributionFee = true;
      }
      if (this.selectedBusiness_data) {
        this.canBeEdited = this.selectedBusiness_data.can_be_edited;
      }
    },
    changeProductTypes() {
      const productType = this.productTypes.selected.code;
      if (productType != 0 && this.suppliersList.selected != "") {
        
        const productsBytype = this.filteredTableData.filter((el) => {
          return el.product_type == productType;
        });
        this.tableData = productsBytype;
      }
      if (productType == 0) {
        this.tableData = this.filteredTableData;
      }
    },
    changeSuppliersList() {
      const supplier_id = this.suppliersList.selected.code;
      let productsBySupplier = this.businessProductData.filter((el) => {
        return el.supplier_id == supplier_id 
      });
      this.tableData = productsBySupplier;

      let productList = [];
      productsBySupplier.forEach((item) => {
        productList.push({
          code: item.product_id,
          label: item.product_name
        })
      });
      this.suppliersProduct.options = productList;
      this.suppliersProduct.selected = "";
      this.filteredTableData = this.tableData;
      this.productTypes.selected = {
        code: 0,
        label: this.$t('All')
      }
    },
    changeSuppliersProduct() {
      const product_id = this.suppliersProduct.selected.code;
      const productData = this.businessProductData.filter((el) => {
        return el.product_id == product_id;
      });
      this.tableData = productData;
      this.filteredTableData = this.tableData;
    },
    applyProfit() {
      this.isProfitConfirm = true;
      this.$modal.show('confirmModal');
    },
    applyPoint() {
      this.isProfitConfirm = false;
      this.$modal.show('confirmModal');
    },
    async confirmData() {
      const business_id = this.businessID;
      const percentage_profit = this.productProfit
      let product_id = [];
      this.tableData.forEach((el) => {
        product_id.push(el.product_id);
      })
      const params = {
        business_id: business_id,
        percentage_profit: percentage_profit,
        product_id: product_id
      }
      if (this.isProfitConfirm) {
        await this.updateProductProfit({params: params});
        this.productProfit = "";
      } else {
        await this.updateProductPoint({params: params});
        this.ProductPoint = "";
      }
      this.$modal.hide('confirmModal');
      this.changeBusiness({
        business_id: this.businessID,
        distribute_id: this.distributeID
      });
    },
    async saveDistributionFee() {
      const business_id = this.businessID;
      const params = {
        business_id: business_id,
        use_distribution_fee: this.use_distribution_fee,
      }
      await this.updateDistributionFee({params: params});
      this.changeBusiness({
        business_id: this.businessID,
        distribute_id: this.distributeID
      });
    },
    async updateTableData() {
      const business_id = this.businessID;
      const params = {
        business_id: business_id,
        products_list: this.businessProductData,
      };
      await this.updateBusinessProduct(params);
      this.changeBusiness({
        business_id: this.businessID,
        distribute_id: this.distributeID
      });
    }
  }
}
</script>

<style lang="scss" scoped>
label {
  font-size: 16px;
  height: 27px;
  display: block;
}

.fee-block {
  background: #E5E5E5;
  border: 1px solid #D4D4D4;
  padding: 10px;
  label {
    height: unset;
  }
  .default-profile {
    margin-right: 50px;
  }
}

@media screen and (max-width: 767px) {
  .form-item {
    width: 100%;
  }
}

@media screen and (max-width: 635px) {
  .primary-btn {
    height: unset;
    min-height: 44px;
  }
  .w-25,
  .w-50,
  .w-75 {
    width: 100% !important;
  }
}
</style>